'use client'

import { ReactNode } from 'react'
import { useFormStatus } from 'react-dom'

import { Button, ButtonProps } from '../Button'
import Spinner from '../Spinner/Spinner'

type ActionButtonProps = ButtonProps & {
  pending?: boolean
  disabled?: boolean
  children: ReactNode
}

export const ActionButton = ({
  children,
  pending,
  disabled,
  ...props
}: ActionButtonProps) => {
  const formStatus = useFormStatus()
  // If no pending prop is passed, use the form status
  let formPending
  if (!pending) {
    formPending = formStatus.pending
  }

  const isPending = pending || formPending

  const newProps = {
    ...props,
    disabled: isPending || disabled,
    className: `${props.className} ${isPending ? 'text-opacity-0' : ''}`,
  }

  return (
    <Button {...newProps}>
      {children}
      <Spinner
        className={`absolute ${isPending ? 'block' : 'hidden'}`}
        size={6}
      />
    </Button>
  )
}
