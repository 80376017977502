'use client'

import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'

interface FormControlProps {
  children: React.ReactNode
  name: string
  title?: string
  subtitle?: string
  className?: string
  labelSize?: string
  labelFontWeight?: string
  error?: {
    message?: string
  }
}

export const FormControl = ({
  children,
  title,
  subtitle,
  name,
  className,
  labelSize = 'text-base',
  labelFontWeight = 'font-normal',
  error,
}: FormControlProps): JSX.Element => (
  <div className={`${className ?? ''} flex flex-col gap-1`}>
    {title && (
      <div className='flex flex-col'>
        <label
          className={`block ${labelSize} ${labelFontWeight} text-gray-900`}
          htmlFor={name}
        >
          {title}
        </label>
        {subtitle && (
          <span className='mt-1 flex items-center text-sm text-gray-500'>
            {subtitle}
          </span>
        )}
      </div>
    )}
    {children}
    {error && error.message && (
      <div className='flex gap-2'>
        <ExclamationCircleIcon
          className='h-5 w-5 text-red-500'
          aria-hidden='true'
        />
        <span className='text-sm text-red-500'>{error.message}</span>
      </div>
    )}
  </div>
)
